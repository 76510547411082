import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits({ teams }) {
  return (
    <React.Fragment>
      <Title>Teams added</Title>
      <Typography component="p" variant="h4">
        {teams}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on {new Date().getUTCDate()} {new Date().getUTCMonth() + 1}, {new Date().getFullYear()}
      </Typography>
      <div>
        <Link color="primary" href="https://egyscores.com" target='_blank'>
          View egyscores
        </Link>
      </div>
    </React.Fragment>
  );
}
