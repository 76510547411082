import { WindowOutlined } from "@mui/icons-material";

export function saveToken(token) {
    try {
        if (!token) throw new Error("token not provided");
        localStorage.setItem("token", token);
        return 'token saved successfully';

    } catch (e) {
        return e.message

    }


}
export function getToken() {
    try {
        const token = localStorage.getItem("token");
        return token;

    } catch (e) {
        console.error("Error getting token", e.message)
    }


}

export function clearToken(success) {
    try {
        if (success) {
            return localStorage.removeItem("token")
        }
    } catch (e) {
        return e.message

    }

}

