import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import SignIn from './components/sign-in'
import SignUp from './components/sign-up'
import AddVideo from './components/addVideo'
import AddArticle from './components/addArticle'
import EditArticle from './components/editArticle'
import DeleteArticle from './components/deleteArticle'
import CreateTeam from './components/createTeam'
import EditTeam from './components/editTeam'
import Dashboard from './components/dashboard/Dashboard'
import Icon from '@material-ui/core/Icon';
import User, { userContext } from './components/userContext'
import { Protected, Guest } from './utils/routes'
import instance from './utils/axios'
import { clearToken, getToken } from './utils/JWT'
import AddLeague from './components/addLeague'
import DeleteLeague from './components/deleteLeague'

function App() {
  const token = useContext(userContext)
  console.log(token)
  useEffect(() => {
    getIsStillValid()
  }

    , [token])
  return (
    <div className="App">
      <ReactNotification />
      <Router>
        <Routes>
          <Route exact path="/sign-in" element={token ? <Navigate to="/" /> : <SignIn />} />
          <Route exact path="/sign-up" element={token ? <Navigate to="/" /> : <SignUp />} />

          <Route exact path="/" element={token ? <Dashboard /> : <Navigate to="/sign-in" />} />
          <Route exact path="/add-video" element={token ? <AddVideo /> : <Navigate to="/sign-in" />} />
          <Route exact path="/add-main-video" element={token ? <AddVideo /> : <Navigate to="/sign-in" />} />
          <Route exact path="/add-article" element={token ? <AddArticle /> : <Navigate to="/sign-in" />} />
          <Route exact path="/add-league" element={token ? <AddLeague /> : <Navigate to="/sign-in" />} />

          <Route exact path="/delete-league" element={token ? <DeleteLeague /> : <Navigate to="/sign-in" />} />
          <Route exact path="/edit-article" element={token ? <EditArticle /> : <Navigate to="/sign-in" />} />
          <Route exact path="/delete-article" element={token ? <DeleteArticle /> : <Navigate to="/sign-in" />} />
          <Route exact path="/create-team" element={token ? <CreateTeam /> : <Navigate to="/sign-in" />} />
          <Route exact path="/edit-team" element={token ? <EditTeam /> : <Navigate to="/sign-in" />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;

async function getIsStillValid() {
  try {
    const response = await instance.get('/api/dash-board', { headers: { 'authorization': `Bearer ${getToken()} ` } });
    console.log(response.data)


  } catch (e) {
    console.log(e)
    clearToken(true)
  }

}