import { createContext } from 'react'
import { Component } from 'react'
import { getToken } from '../utils/JWT'
import { Navigate } from 'react-router-dom'
export const userContext = createContext();
class Client extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            isLoading: false
        }
    }
    componentDidMount() {
        this.setState({ token: getToken() })
    }
    render() {

        return (<userContext.Provider value={this.state.token}>
            {this.props.children}
        </userContext.Provider>)

    }
}

export default Client;