import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import instance from '../utils/axios'
import notification from '../utils/notification';
import { saveToken } from '../utils/JWT'
import { useNavigate, useLocation } from 'react-router-dom'
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://egyscores.com/">
                egyscores.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function AddVideo(props) {
    const { pathname } = useLocation()
    const isMainVideo = pathname.split('/')[1] == 'add-main-video';
    const navigate = useNavigate();
    const videoRef = React.useRef(null)
    const urlRef = React.useRef(null)
    const [videoUrl, setVideoUrl] = React.useState('');
    const [fixtureId, setFixtureId] = React.useState('');
    const [language, setLanguage] = React.useState('english');
    const handleLanguage = (event) => {
        setLanguage(event.target.value);
        console.log(event.target.value);
    };
    const name = 'article-title';
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        let artilceTitle = data.get(name);
        let ImageUpload = data.get('image-upload');
        const teamId = data.get('team-id')
        let LIMIT_TO_REDIRECT = 1000 * 1;
        try {
            let formData = new FormData();
            formData.append('teamId', teamId);
            formData.append('name', artilceTitle);
            formData.append('logo', ImageUpload);
            console.log(teamId)
            const response = await instance.post(`/api/team`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            const { data } = response;
            const { message, success } = data;

            notification('added successfully', message, 'success')

            setVideoUrl('')
            setFixtureId('')

        }
        catch (err) {
            console.log(err)
            return notification('error', err.response.message || err.message, 'warning')
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <VideoSettingsIcon ></VideoSettingsIcon>
                    <Typography component="h1" variant="h5">
                        {pathname.split('/')[1].split('-').join(' ').toUpperCase()}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="video"
                            label="team id"
                            name="team-id"
                            autoComplete="url"

                            autoFocus

                        />

                        <TextField
                            ref={videoRef}
                            margin="normal"
                            required
                            fullWidth
                            id="video"
                            label=" team name"
                            name="article-title"
                            autoComplete="url"
                            value={videoUrl}
                            onChange={(e) => setVideoUrl(e.target.value)}

                            autoFocus

                        />


                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            name="image-upload"
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="raised " fullWidth sx={{ mt: 3, mb: 2 }} component="span" >
                                Upload
                            </Button>
                        </label>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            create Team
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}

                            <Grid item>
                                <Link href="/" variant="body2">
                                    {"return back to dashBoard"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}