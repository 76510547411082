import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import instance from '../utils/axios'
import notification from '../utils/notification';
import { saveToken } from '../utils/JWT'
import { useNavigate, useLocation } from 'react-router-dom'
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://egyscores.com/">
                egyscores.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function AddVideo(props) {
    const { pathname } = useLocation()
    const isMainVideo = pathname.split('/')[1] == 'add-main-video';
    const navigate = useNavigate();
    const videoRef = React.useRef(null)
    const urlRef = React.useRef(null)
    const [videoUrl, setVideoUrl] = React.useState('');
    const [fixtureId, setFixtureId] = React.useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        let videoUrl = data.get('videoUrl');
        let fixtureId = data.get('fixtureId');
        let LIMIT_TO_REDIRECT = 1000 * 1;
        try {
            const response = await instance.post('/api/video', { url: videoUrl, fixtureId: isMainVideo ? 1 : fixtureId });
            const { data } = response;
            const { message } = data;
            notification('login success', message, 'success')
            setVideoUrl('')
            setFixtureId('')

        }
        catch (err) {
            return notification('error', err.message, 'warning')
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <VideoSettingsIcon ></VideoSettingsIcon>
                    <Typography component="h1" variant="h5">
                        {pathname.split('/')[1].split('-').join(' ').toUpperCase()}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            ref={videoRef}
                            margin="normal"
                            required
                            fullWidth
                            id="video"
                            label="video URL"
                            name="videoUrl"
                            autoComplete="url"
                            value={videoUrl}
                            onChange={(e) => setVideoUrl(e.target.value)}

                            autoFocus

                        />
                        {!isMainVideo &&
                            <TextField
                                ref={urlRef}
                                margin="normal"
                                required
                                fullWidth
                                name="fixtureId"
                                label="fixture ID"
                                type="text"
                                id="fixtureId"
                                value={fixtureId}
                                onChange={(e) => setFixtureId(e.target.value)}
                                autoComplete="fixtureId"
                            />
                        }
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Add URL
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid> */}

                            <Grid item>
                                <Link href="/" variant="body2">
                                    {"return back to dashBoard"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}