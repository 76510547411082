import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoginIcon from '@mui/icons-material/Login';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import Card from './card.jsx';
import instance from '../../utils/axios'
import { clearToken, getToken } from '../../utils/JWT'
import notification from '../../utils/notification';
import { InfoRounded } from '@material-ui/icons';

const TIME_LIMIT = 1000 * 2
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://egyscores.com/">
        egyscores.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function createData(time, amount) {
  return { time, amount };
}
function getHour(fixture) {
  const date = new Date(fixture.date)

  return date.getHours();
}

const matchesData = []
// async function MakeData(url) {
//   const { data } = await instance.get(url);
//   const { leagues } = data;
//   let hoursAndMatches = {}
//   for (let league in leagues) {
//     const { fixtures } = leagues[league];
//     console.log(fixtures)
//     if (fixtures.length) {
//       fixtures.forEach(function (match) {
//         console.log(match.fixture)
//         let hour = getHour(match.fixture.date)
//         if (hoursAndMatches[hour])
//           hoursAndMatches[hour] += 1;
//         else {
//           hoursAndMatches[hour] = 1;
//         }
//         console.log(hoursAndMatches[hour])
//         matchesData.push(createData(`${hour}`, `${hoursAndMatches[hour]}`))

//       })
//     }
//   }
//   localStorage.setItem('maches', matchesData)
// }
// MakeData(`/api/matches?date=${new Date()}`)

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [user, setUser] = React.useState({});
  const [teams, setTeams] = React.useState(0)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const matches = localStorage.getItem('matches')
  const toggleDrawer = () => {
    setOpen(!open);
  };
  React.useEffect(() => {

    try {
      instance.get('/api/dash-board', { headers: { 'authorization': `Bearer ${getToken()} ` } }).then(({ data }) => {
        try {
          const { user, teams } = data;
          setUser(user)
          setTeams(teams)
        } catch (error) {
          clearToken(true)
          window.location.reload();
        }


      })
    }
    catch (error) {
      console.log(error)
      clearToken(true)
      window.location.reload();
    }


  }, [matchesData])
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton onClick={logout} color="inherit">
              <LoginIcon></LoginIcon>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"

                    sx={{ flexGrow: 1 }}
                  >
                    welcome {user.name} , this is egyscores dashBoard

                  </Typography>
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits teams={teams} />
                </Paper>

              </Grid>

              {/* Recent Orders */}
              {/* <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Orders />
                </Paper>
              </Grid> */}

            </Grid>
            <Card sx={12} title="egyscores" description="add videos to fixtures" action1='add' action2='add main' ></Card>

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
function logout() {
  clearToken(true)
  console.log(getToken());
  notification('logout', 'logout success');
  setTimeout(() => window.location.reload(), TIME_LIMIT)

}
export default function Dashboard() {
  return <DashboardContent />;
}

